<template>
  <LiefengContent>
    <template v-slot:title>台账档案</template>
    <template v-slot:contentArea>
      <div class="container">
        <div v-for="(item, index) in cardList" :key="index" class="card">
          <div v-for="(items, indexs) in item" :key="indexs" class="cardContent" :style="{marginTop: items.margin}">
            <Card
              :bordered="false"
              :shadow="true"
            >
              <p slot="title">{{ items.title }}</p>
              <a href="#" slot="extra" @click="gotoPage(items.url)">
                {{ items.rightText }}
              </a>
              <p>
                {{ items.content }}
              </p>
            </Card>
            <Icon type="ios-arrow-down" size="80" v-if="items.iconShow"/>
          </div>
        </div>
      </div>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/archivesindex')
import LiefengContent from "@/components/LiefengContent3";
export default {
  data() {
    return {
      //卡片列表数据
      cardList: {
        card1: [
          {
            title: "需求评估",
            rightText: "进入需求评估表总览",
            url: "/archivesdemand",
             iconShow: true,
            content:
              "社工站联合村、居委，通过上门探访等形式对特殊困难群体进行需求摸查，评估服务对象的基本情况与服务需求，包含身体健康状况、家庭成员关系、经济收入情况、社会支持网络、救助申请情况等，并对需求进行分层分类",
          },
          {
            title: "档案",
            rightText: "进入档案管理总览",
            url: "/archivesmanage",
             iconShow: false,
            content:
              "社工站按照“一人一档”原则建立特殊困难群体的服务信息档案，并根据服务开展情况及时更新",
          },
        ],
        card2: [
          {
            title: "计划管理",
            rightText: "进入计划管理",
            url: "/archivesplan",
             iconShow: true,
            content:
              "社工站根据需求评估情况，制定总体服务计划与个案服务计划，服务计划包含服务目标、服务内容、服务形式、时间地点等",
          },
          {
            title: "开展服务",
            rightText: "进入开展服务总览",
            url: "/archivesdevservice",
             iconShow: true,
            content:
              "社工站为特殊困难群体提供定期探访、个案跟踪等有针对性的服务，链接志愿与慈善资源，解决特殊困难群体实际需要",
          },
          {
            title: "服务评估",
            rightText: "进入服务评估",
            url: "/archivesassessment",
             iconShow: false,
            content:
              "社工站结合已有评估体系，综合评估服务对象问题与困难的改善情况",
          },
        ],
        card3: [
          {
            title: "转介登记",
            rightText: "进入转介登记",
            url: "/archivesreferral",
             iconShow: true,
            content:
              "特殊困难群体有转介服务需求的，社工站及时与镇（街）做好沟通协调，链接其他社会组织、专业力量资源提供适切服务",
          },
          {
            title: "定期回访",
            rightText: "进入定期回访",
            url: "/archivesreturnvisit",
            iconShow: false,
            content:
              "社工站在服务结束后可通过电访、上门探访等形式定期回访，持续动态跟踪特殊困难群体状况",
          },
          {
            title: "停止服务管理",
            rightText: "进入停止服务管理",
            url: "/archivesstopservice",
            iconShow: false,
            margin: '80px',
            content: "因不可抗力不再跟进服务登记，在此管理停止服务的服务对象",
          },
        ],
      },
    };
  },
  methods: {
    //跳转到对应页面
    gotoPage(url) {
      this.$router.push(url);
    },
  },
  components: {
    LiefengContent,
  },
};
</script>
    
<style scoped lang='less'>
/deep/#contentarea {
  background: #eee;
  padding: 20px;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
       width: 30%;
        height: calc((100vh - 95px) / 3) ;
        min-height: 300px;
       display: flex;
       flex-direction: column;
       align-items: center;
       .cardContent {
         text-align: center;
       }
       /deep/.ivu-card {
        text-align: left;
        height: calc((100vh - 255px) / 3) ;
         min-height: 160px;
         overflow: auto;
       }
       .ivu-icon {
         color: #bbb;
       }
    }
  }
}
</style>